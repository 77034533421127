@import "../main/abstracts/index";

.section--direct-link-custom{

    .direct-link-custom{
        display: flex;
        box-shadow: 0 2px 11px rgba(0,0,0,.4);
        background-color: $color__tertiary;

        @include media-breakpoint-down(xxl) {

            .direct-link-custom__item a{
                padding: 45px 30px;
            }
        }

        @include media-breakpoint-down(xl) {

            .direct-link-custom__item {

                a{
                    padding: 35px 30px;
                }

                img{
                    margin-bottom: 12px;
                }
            }
        }

        @include media-breakpoint-down(lg) {

            .direct-link-custom__item {

                a{
                    padding: 35px 20px;
                }

                img{
                    height: 43px;
                }
            }
        }

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;

            .direct-link-custom__item{
                flex: auto;
                width: 50%;

                a{
                    padding: 20px 30px;
                }

                &:nth-of-type(2) h3:before{
                    display: none;
                }
            }
        }

        @media (max-width: 450px) {

            .direct-link-custom__item{
                width: 100%;

                &:nth-of-type(2) h3:before{
                    display: block;
                }

                h3:before{
                    right: auto;
                    bottom: 0;
                    top: auto;
                    height: 1px;
                    width: 80%;
                    transform: translate(-50%, 0%);
                    left: 50%;
                }
            }
        }

        &__item{
            position: relative;
            flex: 1 1 0;
            background-color: transparent;
            transition: all .25s ease;

            &:last-of-type h3:before{
                display: none;
            }

            h3{
                height: 100%;

                &:before{
                    position: absolute;
                    content: '';
                    right: 0;
                    top: 18%;

                    height: 64%;
                    width: 1px;
                    background-color: $color__white;
                }
            }

            a{
                position: relative;
                padding: 55px 30px;
                height: 100%;

                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-wrap: wrap;

                text-align: center;
                text-decoration: none;
                transition: all .25s ease-in-out;
            }

            @include hover-focus-within{

                a{
                    color: $color__white;
                    background-color: $color__primary;
                }

                img{
                    filter: invert(1);
                }

                .h3{
                    color: $color__white;
                }
            }

            img{
                height: 48px;
                width: auto;
                margin-bottom: 20px;

                filter: $filter__secondary;
                transition: all .25s ease;
            }

            .h3{
                width: 100%;
                display: block;

                color: $color__font;
                @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 15px, 20px);
                transition: all .25s ease;
            }
        }
    }

    &.section--bg-dark{
        header h2{
            color: $color__white;
        }
    }
}
